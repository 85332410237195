import { render, staticRenderFns } from "./SwiperPagination.vue?vue&type=template&id=21dbfb63&"
import script from "./SwiperPagination.vue?vue&type=script&lang=js&"
export * from "./SwiperPagination.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports